<template src="./CourseRegistration.html"></template>

<script>
import coursesService from "@/services/Courses";
import { mapGetters } from "vuex";
import locationService from "../../services/Locations";

export default {
  name: "CourseRegistration",
  data() {
    return {
      file: null,
      codeCourse: this.$route.params.courseId,
      selectedOptions: [],
      checkboxAuthorization: false,
      imageDescription: "fotoDescripción",
      type: "",
      course: "type",
      AllCoursesByInstitution: [],
      selectedCourse: null,
      isActive: false,
      infoFile: {},
      step: 1,
      dataStructure: {
        personalInformation: [
          {
            inputType: "sex",
            required: false,
            question: "Sexo",
            response: "girl",
          },
          {
            inputType: "age",
            required: true,
            question: "Edad",
            response: 5,
          },
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        contactDetails: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        housingData: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
          {
            inputType: "location",
            required: false,
            question: "locations",
            response: {
              country: "",
              states: "",
              city: "",
              commune: "",
            },
          },
        ],
        studyData: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        familyData: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        populationData: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        interestData: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        capabilities: [
          {
            inputType: "questionsCapabilities",
            required: false,
            question: "questions",
            response: [
              {
                option: "option",
                selected: false,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
        others: [
          {
            inputType: "textLong",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "shortText",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "date",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "addresses",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "mail",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "dateBirth",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "number",
            required: false,
            question: "question",
            response: 5,
          },
          {
            inputType: "select",
            required: false,
            question: "question",
            response: "response",
          },
          {
            inputType: "multiSelect",
            required: false,
            question: "question",
            response: [
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: true,
              },
              {
                option: "option",
                selected: false,
              },
            ],
          },
        ],
      },
      error: false,
      countryOptions: ["Colombia"],
      stateOptions: [],
      citiesOptions: [],
      communeOptions: [],
    };
  },
  methods: {
    async getCourseFormInformation() {
      try {
        this.dataStructure = await coursesService.getFormPreEnrollInformation(
          this.$route.params.courseId
        );
        console.log(this.dataStructure);
        this.buildResponseMultiselect();
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response?.data?.message ?? "Se presentó un error",
          position: "is-bottom",
          type: "is-primary",
        });
        console.log("Error on getCourseFormInformation", e);
        this.$router.push("/academico");
      }
    },
    deleteKey() {
      const self = this;
      Object.keys(this.dataStructure).map((element) => {
        if (typeof self.dataStructure[element] === "object") {
          self.dataStructure[element].map((question, index) => {
            if (
              question.inputType === "select" ||
              question.inputType === "multiSelect" ||
              question.inputType === "location" ||
              question.inputType === "sex"
            ) {
              Reflect.deleteProperty(
                self.dataStructure[element][index],
                "answers"
              );
            }
          });
        }
      });
    },
    buildResponseMultiselect() {
      const self = this;
      Object.keys(this.dataStructure).map((element) => {
        if (typeof self.dataStructure[element] === "object") {
          self.dataStructure[element].map((question, index) => {
            if (question.inputType === "multiSelect") {
              const response = self.dataStructure[element][index].answers;
              self.dataStructure[element][index].response = [];
              response.map((element1) => {
                self.dataStructure[element][index].response.push({
                  option: element1,
                  selected: false,
                });
              });
            }
            if (question.inputType === "location") {
              self.dataStructure[element][index].response = {
                country: "Colombia",
                states: "",
                city: "",
                commune: "",
              };
            }
          })
        }
      })
    },
    buildResponse(indexPattern, indexItem, key) {
      this.dataStructure[key][indexPattern].response[indexItem].selected =
        !this.dataStructure[key][indexPattern].response[indexItem].selected;
    },
    async createPreEnrollCourse() {
      try {
        this.deleteKey();
        this.dataStructure.capabilities[1].response = "si";
        const data = {
          userId: parseInt(this.user.id),
          codeCourse: parseInt(this.$route.params.courseId),
          formEnrollCourseId: this.dataStructure.id,
          ...this.dataStructure,
        };
        Reflect.deleteProperty(data, "id");
        Reflect.deleteProperty(data, "name");
        Reflect.deleteProperty(data, "important");
        Reflect.deleteProperty(data, "dataTreatment");
        Reflect.deleteProperty(data, "remember");
        await coursesService.createPreEnrollCourse(data);
        this.$router.push("/academico");
      } catch (e) {
        console.log("error", e);
      }
    },
    changeStep() {
      if (this.step != 3) {
        this.step = this.step + 1;
      } else {
        this.error = false;
        for (const key of Object.keys(this.dataStructure)) {
          if (Array.isArray(this.dataStructure[key])) {
            this.error = this.dataStructure[key].some((item) => {
              const empty =
                item.response !== undefined &&
                item.response !== null &&
                item.response !== "";
              return item.required && !empty;
            });
            if (this.error) {
              console.log("El culpable es", key);
              break;
            }
          }
        }
        console.log(
          "Por favor llene todos los campos *obligatorios",
          this.error
        );
        if (!this.error) this.step = this.step + 1;
        else {
          this.$buefy.toast.open({
            duration: 5000,
            message: "Por favor llene todos los campos *obligatorios",
            position: "is-bottom",
            type: "is-primary",
          });
        }
      }
    },
    async getCountries() {
      try {
        this.countryOptions = await locationService.getCountries();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getStatesChange(country) {
      try {
        this.stateOptions = await locationService.getStates(country);
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCitiesChange(country, state) {
      console.log("llega", country, state);
      try {
        if ((country, state)) {
          this.citiesOptions = await locationService.getCities(country, state);
        } else {
          this.citiesOptions = await locationService.getCities(
            "Colombia",
            "Antioquia"
          );
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCommunes() {
      this.communeOptions = await locationService.getCommunes();
    },
  },
  created() {
    this.getStatesChange('Colombia');
    this.getCountries();
    this.getCommunes();
    this.getCourseFormInformation();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped lang="scss" src="./CourseRegistration.scss"></style>
